<template>
  <main>
    <linnox-grid>
      <div class="item wudstik">
        <caption>
          WUDSTIK
        </caption>
        <div class="overlay" />
        <svg
          version="1.1"
          id="wudstikLogo"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <path
            d="M62.8,284.5c8-12.1,17.6-19.9,19.4-20c1.7,0-6.5,11.2-10.1,21.9c-5.2,15.7-2.9,32.5,10.2,34.9c25.2,4.7,26.1-43.2,35.4-43.9 c7.7-0.6,43.8,37.7,73.6,47.2c98.8,31.6,106.9-42.5,74.4-74c-62.3,112.5-191.1-0.2-159.3-63.2c13.8-27.3,56.7-33.4,137.8,2.6 c7.1-14.4,19.8-40.1,30.5-52.4c75.4-86.2,200.9-47.2,175.7,49.1c-10.2,38.9-76.1,74.8-116,15.9c15.9-6.6,15-21.7,9.3-27.4 c-10.7-10.7-39.8-8-57.7,33.4c134.4,82.3,117.3,221-17.9,199.2C204.4,397.5,146.8,340.5,127,318c-13.2,62-38,72.6-55,70.4 c-9.9-1.3-27.3-17.1-25.5-52.4C47.5,319.9,52.1,300.8,62.8,284.5z M114.2,221.1c10.5,47,86.8,77.8,117.7-3 C183.9,177.6,99.5,155.5,114.2,221.1z"
          />
        </svg>
      </div>
      <div
        class="item"
        v-for="(item, n) in projectItems"
        :key="n"
        @click="showCurrentItem(item)"
        :style="`grid-column: span ${
          window.width >= 700 ? item.width_desktop : item.width_mobile
        }; grid-row: span ${
          window.width >= 700 ? item.height_desktop : item.height_mobile
        };`"
      >
        <caption>
          {{
            item.name
          }}
        </caption>
        <div class="overlay" />
        <img v-if="item.image != ''" :src="apiPublicUri + item.image" />
      </div>
    </linnox-grid>
    <portal v-if="selectedItem != null" to="linnoxModalBody">
      <div class="modal-item" :class="selectedItem.template == 1 ? 'top' : ''">
        <div class="image">
          <img
            v-if="selectedItem.image != ''"
            :src="apiPublicUri + selectedItem.image"
          />
        </div>
        <div class="text">
          <h1 class="title">{{ selectedItem.name }}</h1>
          <div class="category">
            -- {{ projectCategories[selectedItem.category] }}
          </div>
          <div v-if="selectedItem.spotify != ''" class="album">
            <iframe
              :src="`https://open.spotify.com/embed/album/${selectedItem.spotify}?theme=0`"
              width="100%"
              :height="computeSpotifyHeight(selectedItem.spotify_tracks)"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
           <div v-if="selectedItem.spotify_playlist != ''" class="playlist">
            <iframe
              :src="`https://open.spotify.com/embed/playlist/${selectedItem.spotify_playlist}?theme=0`"
              width="100%"
              :height="computeSpotifyHeight(selectedItem.spotify_playlist_tracks)"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
          <div v-if="selectedItem.youtube != ''" class="video">
            <liVideoEmbed :src="`https://www.youtube.com/embed/${selectedItem.youtube}?rel=0`"/>
          </div>
          <div class="body" v-html="selectedItem.description" />
        </div>
      </div>
    </portal>
    <LiModal @close="closeModal" :showModal="showModal" />
  </main>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      projectCategories: ["Album", "EP", "Single", "Project", "News"],
      selectedItem: null,
      projectItems: null,
      apiPublicUri: "https://api.wudstik.nl",
      apiUri: "https://api.wudstik.nl",
      showModal: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    $route(to) {
      if (
        to.name == "Item" &&
        to.params.id != "" &&
        to.params.id != undefined
      ) {
        this.loadItem();
      } else {
        if (this.selectedItem != null) {
          this.hideCurrentItem();
        }
      }
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    computeSpotifyHeight(tracks) {
      console.log(tracks);
      if (tracks == "1") {
        return 80;
      } else if (tracks == "2") {
        return 151;
      } else {
        return 80 + 31 * tracks;
      }
    },
    showCurrentItem(item) {
      this.$router.push(
        "/item/" + item.id + "/" + this.$options.filters.toSeoUrl(item.name)
      );
    },
    loadItem() {
      const _uri = this.apiUri + "/collection/getCollection";
      const _request = {
        object: "li_projects",
        where: "status [=>] 1 || id [=>]" + this.$route.params.id,
        orderBy: "sequence ASC",
        select:
          "name,image,description,category,spotify,spotify_tracks,spotify_playlist_tracks,spotify_playlist,id,template,youtube",
      };
      this.$apiGet.post(_uri, _request).then((result) => {
        this.selectedItem = result.data.payload[0];
        this.openModal();
      });
    },
    hideCurrentItem() {
      this.selectedItem = null;
      this.showModal = false;
      document.body.classList.remove("__li_modal_open");
    },
    closeModal() {
      this.$router.push("/");
    },
    openModal() {
      this.showModal = true;
      document.body.classList.add("__li_modal_open");
    },
  },
  mounted() {
    const _uri = this.apiUri + "/collection/getCollection";
    const _request = {
      object: "li_projects",
      where: "status [=>] 1",
      orderBy: "sequence ASC",
      select:
        "name,image,category,id,width_mobile,height_mobile,width_desktop,height_desktop",
    };
    this.$apiGet.post(_uri, _request).then((result) => {
      this.projectItems = result.data.payload;
    });

    // CHECK IF WE HAVE AN ITEM?
    if (
      this.$route.name == "Item" &&
      this.$route.params.id != "" &&
      this.$route.params.id != undefined
    ) {
      this.loadItem();
    }
  },
};
</script>
