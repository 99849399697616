import Vue from "vue";
// import moment from "moment";

/**
 * Returns the value of a date in the format "from now"
 *
 * @param val String (containg a date in some format)
 */
// Vue.filter("formatDate", (val) => {
//   if (!val) {
//     return "-";
//   }
//   let date = val.toDate();
//   return moment(date).fromNow();
// });

/**
 * Truncates a string to a given length (200 characters by default)
 *
 * @param str String
 * @param length Int length of the returned string
 */
Vue.filter("trimLength", (str, length) => {
  length = typeof length === "undefined" ? 200 : length;
  if (str.length < length) {
    return str;
  }
  return `${str.substring(0, length)}...`;
});

/**
 * Capitalizes the first letter of a string
 *
 * @param str String
 */
Vue.filter("capitalizeFirstLetter", (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string, standard "_".
 */
Vue.filter("decamelize", (str, separator) => {
  separator = typeof separator === "undefined" ? "_" : separator;
  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
});

/**
 * Creates SEO friendly string from a not so SEO friendly string
 *
 * @param str String not so SEO friendly 
 */
Vue.filter("toSeoUrl", (str) =>{
    return str.toString()           // Convert to string
    .normalize('NFD')               // Change diacritics
    .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
    .replace(/\s+/g,'-')            // Change whitespace to dashes
    .toLowerCase()                  // Change to lowercase
    .replace(/&/g,'-and-')          // Replace ampersand
    .replace(/[^a-z0-9-]/g,'')     // Remove anything that is not a letter, number or dash
    .replace(/-+/g,'-')             // Remove duplicate dashes
    .replace(/^-*/,'')              // Remove starting dashes
    .replace(/-*$/,'');             // Remove trailing dashes
});
