<template>
  <linnox-modal>
    <transition name="pop">
      <div
        class="modal"
        :style="{ transitionDelay: popDelay }"
        role="dialog"
        v-if="showModal"
      >
        <div @click="closeModal" class="modal-close"></div>
        <portal-target name="linnoxModalBody"> </portal-target>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="modal-backdrop"
        v-if="showModal"
        @click="closeModal"
        :style="{ cursor: closeCursorwhite, transitionDelay: fadeDelay }"
      />
    </transition>
  </linnox-modal>
</template>

<script>
export default {
  name: "LiModal",
  data() {
    return {
      popDelay: ".4s",
      fadeDelay: "0s",
      closeCursorBlack: `url("data:image/svg+xml,%3Csvg version='1.1'  id='close' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23000000;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='30.53,2.63 29.37,1.47 16,14.84 2.63,1.47 1.47,2.63 14.84,16 1.47,29.37 2.63,30.53 16,17.16 29.37,30.53 30.53,29.37 17.16,16 '/%3E%3C/svg%3E%0A")16 16, auto`,
      closeCursorwhite: `url("data:image/svg+xml,%3Csvg version='1.1'  id='close' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='30.53,2.63 29.37,1.47 16,14.84 2.63,1.47 1.47,2.63 14.84,16 1.47,29.37 2.63,30.53 16,17.16 29.37,30.53 30.53,29.37 17.16,16 '/%3E%3C/svg%3E%0A")16 16, auto`,
    };
  },
  props: {
    showModal: {
      required: true,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.popDelay = "0s";
      this.fadeDelay = ".4s";
      setTimeout(() => {
        this.$emit("close");
      }, 100);
      setTimeout(() => {
        this.fadeDelay = "0s";
        this.popDelay = ".4s";
      }, 500);
    },
  },
};
</script>
