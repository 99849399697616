import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// AXIOS
import axios from 'axios'
Vue.prototype.$apiGet = axios

// MIXIN
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// FILTERS
import './filters/filters';

// COMPONENTS
// MODAL
import LiModal from './components/LiModal'
Vue.component('LiModal', LiModal);
// VIDEO EMBED
import LiVideoEmbed from './components/LiVideoEmbed'
Vue.component('LiVideoEmbed', LiVideoEmbed);


// CONFIG
Vue.config.ignoredElements = ['linnox-modal','linnox-grid'];
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
